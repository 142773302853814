import React from "react";
import { Typography, Table, Tag } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const Rates2 = () => {
  const { Title } = Typography;
  const [rates, setRates] = useState([]);
  const [id, setId] = useState("");
  const [show, setshow] = useState(false);
  const [updated, setUpdated] = useState({});

  const FetchOrders = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/rates2/getRates"
      );
      setRates(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchOrders();
  }, []);

  const dataSource = [
    ...rates.map((elem, i) => {
      return {
        key: i + 1,
        sno: i + 1,
        flag: <img src={elem.FLAG} style={{ height: "30px" }} />,
        country: elem.CRIPTO,
        fees: elem.FEE,
        exchange: elem.EXCHRATE,
        actions: (
          <button
            className="btn btn-primary fs-6"
            onClick={() => {
              setshow(true);
              setId(elem);
            }}
          >
            Edit
          </button>
        ),
      };
    }),
  ];

  const columns = [
    {
      title: "SNO.#",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Image",
      dataIndex: "flag",
      key: "flag",
    },
    {
      title: "CriptoMoneda",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Fees",
      dataIndex: "fees",
      key: "fees",
    },
    {
      title: "Exchange",
      dataIndex: "exchange",
      key: "exchange",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const SaveChanges = async () => {
    try {
      await axios.put(
        process.env.REACT_APP_BASE_URL + `/api/rates2/edit/${id._id}`,
        {
          EXCHRATE: updated.EXCHRATE,
          FEE: updated.FEE + " €",
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      FetchOrders()
      setshow(false);
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div>
      <Title level={window.matchMedia("(max-width: 600px)").matches ? 4 : 3}>
        All Countries
      </Title>
      <Table
        dataSource={dataSource}
        columns={columns}
        className="responsiveTable transactions"
      />

      {show && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "100vh",
            background: "rgba(0,0,0,0.3)",
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%"
          }}
        >
          <div className="bg-white w-50 p-4 rounded">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header border-bottom pb-2">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Edit {id.COUNTRY}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body mt-3">
                  Current: {id.EXCHRATE}
                  <input
                    className="form-control my-2"
                    placeholder="EXCHRATE"
                    type="text"
                    onChange={(e) =>
                      setUpdated((prev) => ({
                        ...prev,
                        EXCHRATE: e.target.value,
                      }))
                    }
                  />
                  Current: {id.FEE}
                  <input
                    className="form-control mt-2"
                    placeholder="FEE"
                    type="text"
                    onChange={(e) =>
                      setUpdated((prev) => ({ ...prev, FEE: e.target.value }))
                    }
                  />
                </div>
                <div className="modal-footer mt-3">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setshow(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ms-3"
                    onClick={SaveChanges}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Rates2;
