import { Typography, Table, Tag } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { CloseOutlined } from "@ant-design/icons";
import { useRef } from "react";
import Calculator from "../Components/Calculator";
import { useAuthContext } from "../Contexts/AuthContext";

const Transactions = () => {
  const { Title } = Typography;
  const [show, setShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [id, setId] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const status = useRef("false");
  const [loading, setLoading] = useState(false);
  const { amountInp, gets, total } = useAuthContext();

  const FetchOrders = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/orders/getall",
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      setOrders(data.orders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchOrders();
  }, []);

  const data = [
    ...orders.map((elem, i) => {
      return {
        id: elem._id,
        userId: elem.userId,
        orderId: elem.orderId,
        name: elem.name,
        bname: elem.bname,
        date: elem.date,
        cedula: elem.cedula,
        status: [elem.status],
        bbank: elem.bbank,
        bAccnumber: elem.bAccnumber,
        gets: elem.gets,
        totalAmount: elem.totalAmount,
        amount: elem.amount,
        payment: elem.payment,
        NOM: elem.NOM,
        country: elem.country,
        remarks: elem.remarks,
        ptype: elem?.ptype
      };
    }),
  ];

  const columns = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Benificiario",
      dataIndex: "benef",
      key: "benef",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <>
          {status.map((stats) => {
            let color;
            if (
              stats.toUpperCase() === "PENDIENTE" ||
              stats.toUpperCase() === "ANULADA"
            ) {
              color = "volcano";
            } else if (stats.toUpperCase() === "RECIBIDO") {
              color = "blue";
            } else if (stats.toUpperCase() === "COMPLETADA") {
              color = "green";
            } else {
              color = "yellow";
            }
            return (
              <Tag color={color} key={stats}>
                {stats.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const dataSource = [
    ...data.map((elem, i) => {
      return {
        orderId: "TV" + elem.orderId,
        name: elem.name,
        benef: elem.bname,
        cedula: elem.cedula,
        status: elem.status,
        insurance: elem.insurance,
        payment: elem.payment,
        date: elem.date,
        remarks: elem.remarks,
        actions: (
          <>
            <button
              className="btn btn-primary fs-6 me-3"
              onClick={() => {
                setShow(true);
                setId(elem);
              }}
            >
              Change Status
            </button>
            <button
              className="btn btn-warning fs-6 ms-3"
              onClick={async (e) => {
                e.target.innerHTML = "Loading...";
                setId(elem);
                setShowInfo(true);
                status.current = elem.payment;
                e.target.innerHTML = "Details";
              }}
            >
              Details
            </button>
            <button
              className="btn btn-danger fs-6 ms-3"
              onClick={async (e) => {
                e.target.innerHTML = "Loading...";
                try {
                  const confirmm = window.confirm("Are You sure?");
                  if (confirmm) {
                    await axios.post(process.env.REACT_APP_BASE_URL + "/api/orders/deleteorder",
                      {
                        id: elem.id
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                          token: Cookies.get("token"),
                        },
                      }
                    )
                    FetchOrders()
                  }
                } catch (error) {

                }
                e.target.innerHTML = "Delete";
              }}
            >
              Delete
            </button>
          </>
        ),
      };
    }),
  ];

  const HandleStatus = async (status) => {
    const confirmm = window.confirm(
      `Are you sure you want to change status to : ${status}`
    );
    if (confirmm) {
      document.getElementById("bg_status").style.pointerEvents = "none";
      try {
        await axios.post(
          process.env.REACT_APP_BASE_URL + `/api/orders/status`,
          {
            status: status,
            id: id.id,
            orderId: id.orderId.replace("TV", ""),
            user: id.userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        );
        FetchOrders();
        document.getElementById("bg_status").style.pointerEvents = "all";
        setShow(false);
      } catch (err) {
        console.log(err);
        document.getElementById("bg_status").style.pointerEvents = "all";
      }
    }
  };


  const HandleUpdate = async () => {
    try {
      await axios.put(
        process.env.REACT_APP_BASE_URL + `/api/orders/updaterate/${id.id}`,
        {

          amount: amountInp.current.value,
          gets: gets.current.value,
          totalAmount: total.current.value
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      alert("Updated");
      setShowUpdate(false)
      setShowInfo(false)
      amountInp.current.value = ""
      gets.current.value = ""
      total.current.value = ""
      FetchOrders();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div>
        <Title level={window.matchMedia("(max-width: 600px)").matches ? 4 : 3}>
          All Recent Transactions
        </Title>
        <Table
          dataSource={dataSource}
          columns={columns}
          className="responsiveTable transactions"
        />

        {show && (
          <div className="bg_status" id="bg_status">
            <div
              className="d-flex justify-content-center flex-column
             status_modal"
              style={{ position: "relative" }}
            >
              <p className="fs-5 fw-bold">Change Status :</p>
              <CloseOutlined
                style={{
                  fontSize: "1.3rem",
                  position: "absolute",
                  top: "15px",
                  right: "10px",
                }}
                onClick={() => setShow(false)}
              />

              <div className="d-flex justify-content-between align-items-center mt-3">
                <div
                  className="status_box red d-flex justify-content-center 
                align-items-center text-white"
                  type="button"
                  onClick={() => {
                    setLoading(true);
                    HandleStatus("PENDIENTE");
                    setLoading(false);
                  }}
                  aria-disabled={loading}
                >
                  PENDIENTE
                </div>
                <div
                  className="status_box blue d-flex justify-content-center
                 align-items-center text-white"
                  type="button"
                  onClick={() => {
                    setLoading(true);
                    HandleStatus("RECIBIDO");
                    // moneiTokenHandler();
                    setLoading(false);
                  }}
                  aria-disabled={loading}
                >
                  RECIBIDO
                </div>
                <div
                  className="status_box green d-flex justify-content-center
                 align-items-center text-white me-2"
                  type="button"
                  onClick={() => HandleStatus("Completada")}
                >
                  COMPLETADA
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div
                  className="status_box red d-flex justify-content-center
                 align-items-center text-white"
                  type="button"
                  onClick={() => {
                    setLoading(true);
                    HandleStatus("Anulada");
                    setLoading(false);
                  }}
                >
                  ANULADA
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showInfo && (
        <div
          className="modal_bg d-flex justify-content-center 
          align-items-center"
        >
          <div
            className="modal"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog w-100">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Detalles # {id.orderId}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setShowInfo(false);
                      // status.current = "";
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="d-flex flex-wrap align-items-center mt-1">
                    <p
                      className=""
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Orden : {id.orderId}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Fecha : {id.date}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Cédula Beneficiario : {id?.cedula}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Nombre y Apellido Beneficiario : {id.bname}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Numero de Cuenta Beneficiario : {id.bAccnumber}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Entidad Bancaria : {id.bbank}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Cantidad a enviar (EUR) : {id.amount}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Recibe ({id.NOM}) : {id.gets}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Total a pagar : {id.totalAmount}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Country : {id.country}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Forma de Pago : {id?.ptype ? id?.ptype : "Transferencia bancaria"}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      Observaciones especiales: {id.remarks}
                    </p>
                  </div>

                  <button className="btn btn-primary"
                    onClick={() => setShowUpdate(id)}>Edit Rates</button>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setShowInfo(false);
                      // status.current = "";
                    }}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


      {showUpdate && (
        <div
          className="modal_bg d-flex justify-content-center 
          align-items-center"
        >
          <div
            className="modal"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog w-100">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Change Rate
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setShowUpdate(false);
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  <Calculator COUNTRY={id.country} NOM={id.NOM} Envia={id.amount} />
                </div>

                <div className="modal-footer">

                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setShowUpdate(false);
                    }}
                  >
                    Cerrar
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      HandleUpdate();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Transactions;
