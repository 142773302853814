import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../Contexts/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";


const Calculator = ({ COUNTRY, NOM, Envia }) => {
    const { user, amountInp, total, gets } = useAuthContext();
    const countryData = useRef([]);
    const curr2 = useRef();
    const totalFees = useRef();
    const exchangerate = useRef();
    const [data, setData] = useState([]);

    useEffect(() => {
        const Fetch = async () => {
            const { data } = await axios.get(
                process.env.REACT_APP_BASE_URL + "/api/rates/getrates"
            );

            setData(data?.data?.sort((a, b) => a.COUNTRY.localeCompare(b.COUNTRY)));
            setCountry(`${COUNTRY}`, `${NOM}`, data?.data?.sort((a, b) => a.COUNTRY.localeCompare(b.COUNTRY)))
            amountInp.current.value = Envia;
            Calculate();
        };
        Fetch();
    }, []);

    const setCountry = (country, nom, data) => {
        curr2.current.innerHTML = nom;

        let cdata = data.filter((elem) => {
            return elem.NOM.trim() === nom.trim() && elem.COUNTRY.trim() === country.trim();
        });

        countryData.current = (cdata)

        totalFees.current.innerHTML = cdata[0].FEE;
        exchangerate.current.innerHTML = cdata[0].EXCHRATE + " " + nom;
    };

    function Calculate() {
        if (countryData.current.length > 0) {
            let get = (
                amountInp.current.value.replaceAll(".", "").replaceAll(",", ".") *
                countryData.current[0].EXCHRATE.replaceAll(",", "cc").replaceAll(".", "dd")
                    .replaceAll("cc", ".").replaceAll("dd", "")
            ).toLocaleString("en-En", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            gets.current.value = get.replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",")


            let totals = (
                Number(amountInp.current.value.replaceAll(".", "").replace(",", ".")) +
                Number(totalFees.current.innerHTML.replaceAll("€", "")
                    .replaceAll(",", "cc").replaceAll(".", "dd")
                    .replaceAll("cc", ".").replaceAll("dd", ""))
            ).toLocaleString("en-En", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            total.current.value = totals.replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",");
        }
    }



    const format = (e) => {
        var num = e.target.value
            .replaceAll(".", "")
            .replaceAll(" ", "")
            .replaceAll(",00", "")
            .replaceAll(",0", "");

        var num2 = num.split(/(?=(?:\d{3})+$)/).join(".");
        e.target.value = num2.replaceAll(",00", "").replaceAll(",0", "").replaceAll(",", "") + ",00";

        const cursorPosition = e.target.selectionStart;
        const commaPosition = e.target.value.indexOf(',');

        if (cursorPosition <= commaPosition) {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        } else {
            e.target.setSelectionRange(commaPosition, commaPosition);
        }
    };


    return (
        <div className="rounded border calculator">
            <div className="mt-3 px-2">
                <label>Envía</label>
                <div
                    className="bb d-flex mt-1"
                    style={{ position: "relative", width: "100%" }}
                >
                    <input
                        type="text"
                        className="form-control"
                        id="amountInp"
                        inputmode="numeric"
                        onInput={(e) => {
                            format(e);
                            Calculate();
                        }}
                        ref={amountInp}
                    />
                    <div className="curr">EUR</div>
                </div>
            </div>

            <div className="mt-3 px-2">
                <label>Recibe*</label>
                <div
                    className="bb d-flex mt-1"
                    style={{ position: "relative", width: "100%" }}
                >
                    <input
                        type="text"
                        className="form-control bg-white"
                        id="gets"
                        ref={gets}
                        onChange={(e) => format(e)}
                        disabled={true}
                    />
                    <div className="curr" id="curr2" ref={curr2}></div>
                </div>
            </div>
            <p className="text-muted mt-1 px-2">
                Comisión:{" "}
                <span className="fw-bold" id="totalFees" ref={totalFees}></span>
            </p>
            <p className="text-muted px-2">
                Tasa: 1 EUR = {" "}
                <span
                    className="fw-bold"
                    id="exchangerate"
                    ref={exchangerate}
                ></span>
            </p>

            <div className="mt-3 px-2">
                <label>Total a pagar </label>
                <div
                    className="bb d-flex mt-1"
                    style={{ position: "relative", width: "100%" }}
                >
                    <input
                        type="text"
                        className="form-control bg-white"
                        id="total"
                        ref={total}
                        onInput={(e) => format(e)}
                        disabled={true}
                    />
                    <div className="curr">EUR</div>
                </div>
            </div>
            <p className="text-muted px-2 small">
                *Resultado sujeto a tasas y tipos de cambio variables.
            </p>

        </div>
    );
};

export default (Calculator);
