import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Mail = () => {
    const [text, setText] = useState("");
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    

    const HandlSubmit = async () => {
        try {
            setLoading(true)
            await axios.post(
                process.env.REACT_APP_BASE_URL +
                `/api/admin/sendall`,
                {
                    text,
                    title,
                },
                { headers: { token: Cookies.get("token") } }
            );
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    return (
        <div>
            <h1 className='border-bottom mb-3 fw-bold fs-4 pb-2'>Send Mail to All Users</h1>
            <input type='text' placeholder='Subject' className='form-control mb-2' onChange={(e) => setTitle(e.target.value)} />
            <ReactQuill
                value={text}
                onChange={(e) => setText(e)}
                modules={{
                    toolbar: [
                        [{ 'header': [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        // ['link', 'image'],
                        ['clean']
                    ],
                }}
                formats={[
                    'header',
                    'bold', 'italic', 'underline', 'strike',
                    'list', 'bullet',
                    // 'link', 'image',
                ]}
                placeholder="Type something..."
            />

            <button className='btn btn-primary mt-3' onClick={HandlSubmit}>{loading ? "Loading" : "Send To All"}</button>
        </div>
    )
}

export default Mail