import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuthContext } from "../Contexts/AuthContext";
import { Navigate } from "react-router-dom";

const NewOrder = () => {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({bbank: "BANESCO"});
  const countryInp = useRef();
  const curr2 = useRef();
  const exchangerate = useRef();
  const flag = useRef();
  const gets = useRef();
  const totalFees = useRef();
  const country = useRef();
  const amountInp = useRef();
  const total = useRef();
  const [userdata, setUserData] = useState({});
  const [cuenta, setCuenta] = useState(true);
  const [cdata, setCData] = useState([]);

  let countryData = [];


  useEffect(() => {
    const Fetch = async () => {
      const { data } = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/rates/getrates"
      );

      setCData(data?.data);

      curr2.current.innerHTML = data.data[0].NOM;
      countryInp.current.value = data.data[0].COUNTRY;
      exchangerate.current.innerHTML = data.data[0].EXCHRATE;
      flag.current.style.display = "flex";
      flag.current.src = data.data[0].FLAG;
      totalFees.current.innerHTML = data.data[0].FEE;
      countryData = data.data[0];
      // setData(data?.data?.sort((a, b) => a.COUNTRY.localeCompare(b.COUNTRY)));
    };
    Fetch();
  }, []);

  const Submit = async (e) => {
    e.preventDefault();
    if (document.getElementById("bAccnumber").value.length === 20) {
      if (amountInp.current.value >= 5) {
        setLoading(true);
        await axios.post(
          process.env.REACT_APP_BASE_URL + "/api/orders/new",
          {
            name: userdata.name,
            email: userdata.email,
            status: "Pendiente",
            ...data,
            totalAmount: total.current.value,
            gets: gets.current.value,
            manual: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        );

        setUserData({email: "", name: ""})
        setData({bname: "", bbank: "", 
        bAccnumber: "", amount: "",
         bbank: "BANESCO",
         gets: "",
         cedula: ""
      })
        total.current.value = "";
        alert("Tu solicitud ha sido enviada");
        setLoading(false);
      } else {
        alert("Envíos solo a partir de 5,00€");
        setLoading(false);
      }
    }
    else {
      alert("CUENTA DEBE TENER 20 DíGITOS!");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  function Calculate() {
    countryData = cdata.filter((elem) => {
      return (
        elem.NOM === curr2.current.innerHTML &&
        elem.COUNTRY === countryInp.current.value
      );
    });

    if (countryData.length > 0) {
      let get = (
        amountInp.current.value.replaceAll(".", "").replaceAll(",", ".") *
        countryData[0].EXCHRATE.replaceAll(",", "cc").replaceAll(".", "dd")
        .replaceAll("cc", ".").replaceAll("dd", "")
      ).toLocaleString("en-En");
      gets.current.value = get.replaceAll(".", "cc").replaceAll(",", "dd")
      .replaceAll("cc", ",").replaceAll("dd", ".")

      let totals = (
        Number(amountInp.current.value.replaceAll(".", "").replace(",", ".")) +
        Number(countryData[0].FEE.replace("€", "")
        .replaceAll(",", "cc").replaceAll(".", "dd")
          .replaceAll("cc", ".").replaceAll("dd", ""))
      ).toLocaleString();

      total.current.value = amountInp.current.value.includes(".")
        ? totals
          .replace(".", ".,")
          .replace(",", ".")
          .replace(".,", ",")
          .replace("..", ".")
        : totals.replace(".", ".,").replace(".,", ",").replace("..", ".");
    }
  }


  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  const format = (e) => {
    var num = e.target.value.replaceAll(".", "").replaceAll(" ", "");

    var num2 = num.split(/(?=(?:\d{3})+$)/).join(".");
    e.target.value = num2;
  };

  const setCountry = (country, nom) => {
    curr2.current.innerHTML = nom;
    countryInp.current.value = country;

    countryData = cdata.filter((elem) => {
      return elem.NOM === nom && elem.COUNTRY === country;
    });

    totalFees.current.innerHTML = countryData[0].FEE;
    exchangerate.current.innerHTML = countryData[0].EXCHRATE + " " + nom;
    flag.current.style.display = "flex";
    flag.current.src = countryData[0].FLAG;
    Calculate();
  };

  if (!user) return <Navigate to={"/login"} />;

  return (
    <div className="OrderSection">
      <div className="px-5 profile_box mx-auto py-5 mt-10 bg-white rounded position-relative">
        <div className="calculator pb-0 mb-3">
          <form onSubmit={Submit} className="calculator mt-3 pt-0">
            <label className="mt-3">Email</label>
            <input
              className="form-control rounded mt-1"
              type="text"
              placeholder="Email"
              value={userdata.email}
              onChange={(e) =>
                setUserData((prev) => ({ ...prev, email: e.target.value }))
              }
              required
            />
            <label className="mt-3">Name</label>
            <input
              className="form-control rounded mt-1"
              type="text"
              placeholder="Name"
              value={userdata.name}
              onChange={(e) =>
                setUserData((prev) => ({ ...prev, name: e.target.value }))
              }
              required
            />

            <div className="mt-3">
              <label>Destino</label>
              <div
                className="d-flex mt-1"
                style={{ position: "relative", width: "100%" }}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img id="flag" ref={flag} alt={`Transferven`} />
                <input
                  type="text"
                  className="form-control rounded-0"
                  id="countryInp"
                  ref={countryInp}
                  autoComplete="off"
                />
                <div className="dropdown" style={{ position: "static"}}>
                  <button
                    className="btn border dropdown-toggle rounded-0 h-100"
                    type="button"
                  ></button>
                  <ul className="dropdown-menu w-100">
                    {cdata.map((elem) => {
                      return (
                        <li
                          type="button"
                          className="py-1 px-2"
                          onClick={() =>
                            setCountry(`${elem.COUNTRY}`, `${elem.NOM}`)
                          }
                        >
                          {elem.COUNTRY}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <label className="mt-3">Nombre y Apellido Beneficiario</label>
            <input
              className="form-control rounded mt-1"
              type="text"
              placeholder=""
              id="bname"
              onChange={handleChange}
              value={data.bname}
              required
            />

<label className="mt-3">Cédula Beneficiario</label>
              <input
                className="form-control rounded mt-1"
                type="text"
                id="cedula"
                onChange={handleChange}
              value={data.cedula}
                placeholder=""
                required={true}
                inputmode="numeric"
              />

            <div className="d-flex mt-3">
              <div className="d-flex align-items-center">
                <input type="radio" name="cuenta" className="mb-0"
                  checked={cuenta}
                  onChange={(e) => {
                    setCuenta(!cuenta)
                    setData({ ...data, bAccnumber: "" })
                  }
                  }
                />
                <label className="ms-2">Numero de Cuenta Beneficiario</label>
              </div>
              <div className="d-flex ms-5 align-items-center">
                <input type="radio" name="cuenta" className="mb-0"
                  checked={!cuenta}
                  onChange={(e) => {
                    setCuenta(!cuenta)
                    setData({ ...data, bAccnumber: "" })
                  }}
                />
                <label className="ms-2">Pago Movil</label>
              </div>
            </div>
            <input
              className="form-control rounded mt-1"
              type="text"
              id="bAccnumber"
              value={data.bAccnumber}
              onChange={(e) => {
                if (cuenta) {
                  if (e.target.value.length <= 20) {
                    handleChange(e)
                  }
                } else {
                  if (e.target.value.length <= 11) {
                    handleChange(e)
                  }
                }
              }}
              placeholder=""
              required={true}
              min={cuenta ? 20 : 11}
              max={cuenta ? 20 : 11}
            />

            <div className="mt-3">
              <label className="">Entidad Bancaria</label>
              <div className="d-flex align-items-center rounded">
                <div className="dropdown w-100">
                  <button
                    className="d-flex justify-content-between align-items-center px-3 py-2 border dropdown-toggle w-100 bg-white text-start"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="bbank"
                  >
                    {data.bbank}
                  </button>
                  <ul className="dropdown-menu w-100">
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BANESCO
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      VENEZUELA
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      MERCANTIL
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      PROVINCIAL
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BNC
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BANCARIBE
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      PAGO MÓVIL
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BICENTENARIO
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BANCAMIGA
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      EXTERIOR
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      FONDO COMÚN
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BANPLUS
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      VENEZOLANO DE CRÉDITO
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      SOFITASA
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      PLAZA
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      DEL TESORO
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      100% BANCO
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      ACTIVO
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      CARONÍ
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      DEL SUR
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BANCRECER
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      INDUSTRIAL
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BANDEX
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BANCOEX
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BANGENTE
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      INTERNACIONAL DE DESARROLLO
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      AGRÍCOLA
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      MI BANCO
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      IMCP
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BANAVIH
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BANFANB
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData((prev) => ({
                          ...prev,
                          bbank: e.target.innerHTML,
                        }))
                      }
                    >
                      BOD
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="mt-3">
              <label className="">Cantidad a enviar (EUR)</label>
              <div className="d-flex align-items-center rounded">
                <input
                  className="form-control me-2"
                  type="text"
                  placeholder=""
                  required
                  ref={amountInp}
                  id="amount"
                  value={data.amount}
                  onChange={(e) => {
                    handleChange(e);
                    Calculate();
                  }}
                />
              </div>
            </div>

            <div className="mt-3">
              <label className="">Recibe*</label>
              <div className="d-flex rounded">
                <input
                  type="text"
                  className="form-control bg-white"
                  id="gets"
                  ref={gets}
                  value={data.gets}
                  onChange={(e) => format(e)}
                  disabled={true}
                  required
                />
                <div className="curr" id="curr2" ref={curr2}></div>
              </div>
            </div>

            <p className="text-muted mt-1 px-2">
              Comisión:{" "}
              <span className="fw-bold" id="totalFees" ref={totalFees}></span>
            </p>
            <p className="text-muted px-2">
              Tasa: 1 EUR =
              <span
                className="fw-bold"
                id="exchangerate"
                ref={exchangerate}
              ></span>
            </p>

            <div className="mt-3">
              <label>Total a pagar </label>
              <div
                className="border d-flex mt-1"
                style={{ position: "relative", width: "100%" }}
              >
                <input
                  type="text"
                  className="form-control bg-white"
                  id="total"
                  ref={total}
                  onInput={(e) => format(e)}
                  disabled={true}
                  required
                />
                <div className="curr">EUR</div>
              </div>
            </div>

            <label className="mt-3 ">Observaciones especiales:</label>
            <textarea className="form-control rounded mt-1" required={false} />

            <button className="btn btn-primary mt-3 w-auto px-3" type="submit">
              {loading ? "Enviando" : "Enviar"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewOrder;
