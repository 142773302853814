import { Routes, Route } from "react-router-dom";
import Transactions from "./Pages/Transactions";
import Users from "./Pages/Users";
import NewOrder from "./Pages/NewOrder";
import Rates from "./Pages/Rates";
import Rates2 from "./Pages/Rates2";
import Mail from "./Pages/Mail";
import Notificationn from "./Pages/Notification";


export default function Rotes() {
  return (
    <Routes>
      <Route path="/" element={<Users />} />
      <Route path="/new" element={<NewOrder />} />
      <Route path="/transactions" element={<Transactions />} />
      <Route path="/rates" element={<Rates />} />
      <Route path="/rates2" element={<Rates2 />} />
      <Route path="/mails" element={<Mail />} />
      <Route path="/notification" element={<Notificationn />} />


    </Routes>
  );
}
