import { Typography, Table } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

const Users = () => {
  const { Title } = Typography;
  const [data, setData] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [details, setdetails] = useState({});
  const [vloading, setVloading] = useState(false);

  const columns = [
    {
      title: "SNO.#",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const RejectKYC = async () => {
    const confirmm = window.confirm("Are you sure?");
    if (confirmm) {
      await axios.post(
        process.env.REACT_APP_BASE_URL + `/reject`,
        {
          id: details._id,
          imgs: details.img
        },
        { headers: { token: Cookies.get("token") } }
      )

      setdetails(false)
      setShowInfo(false)
    }
  }


  const DeleteImages = async () => {
    const confirmm = window.confirm("Are you sure?");
    if (confirmm) {
      await axios.post(
        process.env.REACT_APP_BASE_URL + `/api/auth/deleteimages`,
        {
          img: details.img,
          id: details._id
        },
        { headers: { token: Cookies.get("token") } }
      )

      setdetails(false)
      setShowInfo(false)
    }
  }

  const DisabledAcc = async (elem) => {
    const reason = prompt("Whats the reason for blocking?");
    if (reason) {
      await axios
        .put(
          process.env.REACT_APP_BASE_URL + `/api/auth/update/${elem._id}`,
          {
            newUser: {
              ...elem,
              verified: false,
            },
          },
          { headers: { token: Cookies.get("token") } }
        )
        .then(async () => {
          await axios.post(
            process.env.REACT_APP_BASE_URL +
            `/api/auth/mailstatus/${elem.email}`,
            {
              msg: `Hola!
        
        ${reason}
        `,
            },
            { headers: { token: Cookies.get("token") } }
          );
        });
    }
  };

  const DeleteAcc = async (elem) => {
    const confirmm = window.confirm("Are you sure?");

    if (confirmm) {
      await axios.post(
        process.env.REACT_APP_BASE_URL + `/api/auth/deleteUser`,
        {
          id: elem._id,
        },
        { headers: { token: Cookies.get("token") } }
      );
    }
  };

  const dataSource = [
    ...data.map((elem, i) => {
      return {
        key: i + 1,
        sno: elem.uid,
        name: elem.name + " " + elem.surname,
        email: elem.email,
        phone: elem.phone,
        direction: elem.direction,
        img: elem.img,
        actions: (
          <>
            <button
              className="btn btn-warning px-2 py-1 me-2"
              onClick={() => DisabledAcc(elem)}
            >
              Disabled
            </button>
            <button
              className="btn btn-danger px-2 py-1"
              onClick={() => DeleteAcc(elem)}
            >
              Delete
            </button>
            <button
              className="btn btn-primary ms-2 px-2 py-1"
              onClick={() => {
                setShowInfo(true);
                setdetails(elem);
              }}
            >
              Details
            </button>
          </>
        ),
      };
    }),
  ];

  const MarkUnVerified = async () => {
    setVloading(false)
    await axios.put(
      process.env.REACT_APP_BASE_URL + `/api/admin/kycstatus/${details.email}`,
      {
        kyc: false
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    ).then(() => {
      setdetails(false)
      setShowInfo(false)
      Fetch()
      setVloading(false)
    })
    setVloading(false)
  }

  const MarkVerified = async () => {
    setVloading(true)
    await axios.put(
      process.env.REACT_APP_BASE_URL + `/api/admin/kycstatus/${details.email}`,
      {
        kyc: true
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    ).then(() => {
      setdetails(false)
      setShowInfo(false)
      Fetch()
      setVloading(false)
    })
    setVloading(false)
  }


  const Fetch = async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_BASE_URL + "/api/admin/users",
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );

    setData(data.users);
  };

  useEffect(() => {
    Fetch();
  });

  return (
    <div>
      <Title level={window.matchMedia("(max-width: 600px)").matches ? 4 : 3}>
        All Registered Users
      </Title>
      <Table
        dataSource={dataSource}
        columns={columns}
        className="responsiveTable"
      />

      {showInfo && (
        <div
          className="modal_bg d-flex justify-content-center 
          align-items-center"
        >
          <div
            className="modal"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Detalles # {details.uid}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShowInfo(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <p className="fs-5 fw-bold text-center">{details.type}</p>
                  <div className="d-flex flex-wrap align-items-center mt-2">
                    <p
                      className=""
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 1. </span>Id : {details.uid}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 7. </span>Name :{" "}
                      {details.name + " " + details.surname}
                    </p>
                    <p
                      className="mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 2. </span>Email : {details.email}
                    </p>

                    <p
                      className="mt-1 "
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 8. </span>Ocupación :{" "}
                      {details.occupation}
                    </p>

                    <p
                      className="mt-1 "
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 3. </span>Phone 1 :{" "}
                      {details.phone}
                    </p>

                    <p
                      className="mt-1 "
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 9. </span>DOB :{" "}
                      {details?.dob}
                    </p>

                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 4. </span>Id type:{" "}
                      {details.doctype}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 10.</span> Id code :{" "}
                      {details.docId}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 550px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 5. </span>Home number :{" "}
                      {details.homenumber}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 11.</span> Home via :{" "}
                      {details.homevia}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 6. </span>Postal code:{" "}
                      {details.postalcode}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 12.</span> Residence:{" "}
                      {details.residence}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 12.</span> Dirección :{" "}
                      {details.direction}
                    </p>
                    <p
                      className=" mt-1"
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <span className="small"> 12.</span> KYC :{" "}
                      {details.kyc ? "Verified" : "Not Verified"}
                    </p>
                    <div className="mt-1" style={{
                      width: window.matchMedia("(max-width: 650px)").matches
                        ? "100%"
                        : "50%",
                    }}></div>
                    {details.kyc === false ? <button className="mt-2 btn btn-primary"
                      onClick={() => MarkVerified()}>{vloading ? "Loading" : "Mark Verified"}</button>
                      :
                      <button className="btn btn-danger mt-2"
                        onClick={() => MarkUnVerified()}>{vloading ? "Loading" : "Mark as Unverified"}</button>
                    }
                  </div>

                  {details?.img?.length > 0 && details?.img.map((elem) => {
                    return <img src={process.env.REACT_APP_BASE_URL + "/images/" + elem} height={200} className="mt-3 me-4" />
                  })}
                  {details?.img?.length > 0 &&
                    <>
                      <button className="btn btn-danger me-2" onClick={() => RejectKYC()}>Reject</button>
                      <button className="btn btn-danger ms-auto" onClick={() => DeleteImages()}>Delete Images</button>
                    </>}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowInfo(false)}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
