import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import 'react-quill/dist/quill.snow.css';

const Notificationn = () => {
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [loading, setLoading] = useState(false);

    const HandlSubmit = async () => {
        try {
            setLoading(true)
            await axios.post(
                process.env.REACT_APP_BASE_URL +
                `/sendnot`,
                {
                    desc,
                    title,
                },
                { headers: { token: Cookies.get("token") } }
            );
            setTitle("")
            setDesc("")
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    return (
        <div>
            <h1 className='border-bottom mb-3 fw-bold fs-4 pb-2'>Send Notification to All Users</h1>
            <input type='text' placeholder='Title' className='form-control mb-2' onChange={(e) => setTitle(e.target.value)} value={title} />
            <input type='text' placeholder='Description' className='form-control mb-2' onChange={(e) => setDesc(e.target.value)} value={desc} />
        
            <button className='btn btn-primary mt-3' onClick={HandlSubmit}>{loading ? "Loading" : "Send To All"}</button>
        </div>
    )
}

export default Notificationn